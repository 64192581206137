import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import StaticMaps from "../components/StaticMaps"

export default function Maps(props) {
  const { location } = props
  return (
    <Layout>
      <SEO title="Static Maps" pagePath={location.pathname} />
      <StaticMaps rootPath={location.origin} />
    </Layout>
  )
}
