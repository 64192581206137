const data = [
  {
    url: "https://i.imgur.com/s9hsjgm.png",
    text:
      "Check out % of unemployed people in my latest time-lapse map of Europe! #unemloyment #economy #europe #datavisualization #dataviz #datascience #maps #rstats #cartography #artwork #visualart...",
    likes: "6947",
    comments: "78",
  },
  {
    url: "https://i.imgur.com/BCyDsBX.png",
    text:
      "My time-lapse map of Europe shows % of people who work in science & technology field (2014-2019) #science #tech #research #researchanddevelopment #DataScience #dataviz #datavisualization #maps #mapping #Cartography #rstats #visualart...",
    likes: "6739",
    comments: "145",
  },
  {
    url: "https://i.imgur.com/QuPOTHP.png",
    text:
      "On International Women's Day, my map shows the proportion of female respondents who have experienced abuse from their partner (2019). The cross-national survey includes the same set of questions for every female participant regardless of their country of residence. On average, 22% of female responde...",
    likes: "6410",
    comments: "110",
  },
  {
    url: "https://i.imgur.com/pLZ9hHV.png",
    text:
      "My latest time-lapse map shows fascinating nightlight activity for ex Yugoslav republics spanning nearly 30 years! These incredible time-consistent data come from 'A harmonized global nighttime light dataset 1992–2018' by Li and Zhou (2017): 👇 https://figshare.com/articles/dataset/Harmonization_...",
    likes: "5268",
    comments: "43",
  },
  {
    url: "https://i.imgur.com/jGAQmyW.png",
    text:
      "Do you like TRAINS? Me, too! Have a closer look at my map of RAILWAYS in Europe using awesome data from Open Street Maps. #railway #railroad #trains #bigdata #osm #datavisualization #dataviz #DataScience #mapping #maps #rstats #developer #visualart...",
    likes: "4412",
    comments: "101",
  },
  {
    url: "https://i.imgur.com/h81wci5.png",
    text:
      "I present you my density map of Yugoslavia's religious groups from 1931. ⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐ Predstavljam vam mapu verskih grupa u Jugoslaviji iz 1931. prema gustini naseljenosti. #history #census #religion #density #bigdata #datavisualization #dataviz #visualart #maps #rstats #gis #cartography #GeoSpat...",
    likes: "4090",
    comments: "219",
  },
  {
    url: "https://i.imgur.com/B69O8Ji.png",
    text:
      "My new map shows average and monthly drought levels in Europe on the local administrative unit level for 2020-2021. Warmer colors indicate HIGHER drought levels. The data originate from SPEI Global Drought Monitor: https://spei.csic.es/map/maps.html#months=1#month=1#year=2021 #drought #climate #da...",
    likes: "4011",
    comments: "26",
  },
  {
    url: "https://i.imgur.com/ovh0Pyv.png",
    text:
      "Since the proclamation of independence from Serbia on 17 February 2008, Kosovo has received sligthly over 100 recognitions (15 of which have been withdrawn) out of 193 UN member states. My map shows the international recognition of Kosovo, including those countries that have withdrawn their recognit...",
    likes: "3588",
    comments: "334",
  },
  {
    url: "https://i.imgur.com/Gx1FxiY.png",
    text:
      "Today is the International day of forests and I created a tree cover density map of Europe on the local administrative unit (LAU) level, using the most recent satellite data from Copernicus Land Monitoring Service #ForestDay #Forests #datavisualization #dataviz #DataScience #maps #mapping #cartogra...",
    likes: "3567",
    comments: "69",
  },
  {
    url: "https://i.imgur.com/cNphMRA.png",
    text:
      "I mapped traffic accidents per 100 km of paved road for every settlement in Serbia (2015-2020) Мапирао сам број саобраћајних незгода на 100 км асфалтираног пута за свако насеље у Србији. #traffic #saobracaj #accident #dataviz #datavisualization #datascience #bigdata #rstats #maps #mapping #cartogr...",
    likes: "3177",
    comments: "16",
  },
]

export default data
